import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { UserProfile } from 'src/app/layout/user-profile/user-profile.model';
import { FIREBASE_STRUCT, firebaseIdms } from 'src/app/app.constant';
import { AngularFireAuth } from '@angular/fire/auth';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { HttpHeaders } from '@angular/common/http';
import { Handling } from 'src/app/decorators/handling';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss'],
})
export class GuideComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject();
  contentHtml = '';
  modalTitle = '';
  contentNode = '';
  qrs = [];
  transferSyntax = '';

  constructor(
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    private navParams: NavParams,
    @Inject(firebaseIdms) private firebaseIdms: AngularFireAuth,
    private apiService: ApiServiceService
  ) {
    this.modalTitle = this.navParams.get('modalTitle');
    this.contentNode = this.navParams.get('contentNode');
  }

  @Handling()
  async ngOnInit() {
    const contentNode: string = this.navParams.get('contentNode');
    const userUid = this.firebaseIdms.auth.currentUser.uid;
    const user: any = await this._getCurrentUser(userUid);
    this.transferSyntax = `PCK ${user.login_name}`;
    if (contentNode === 'home_recharge_guide') {
      await this._generateQR(user);
    }
    
    this.getContent(contentNode).subscribe((content: any) => {
      if (content) {
        this.contentHtml = content.content_html;
      } else {
        this.contentHtml = '';
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public async onCopyText(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }

  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  getContent(contentNode: string) {
    return this.fs.collection('PARAMETER_CONTENT').doc(`${contentNode}`).valueChanges().pipe(
      takeUntil(this.unsubscribe$)
    );
  }

  private async _getCurrentUser(userUid: string) {
    return this.fs.collection<any>(FIREBASE_STRUCT.USERS.NODE).doc<UserProfile>(userUid).snapshotChanges().pipe(
      map(doc => ({ uid: userUid, ...doc.payload.data() })),
      first()
    ).toPromise();
  }

  private async _generateQR(user) {
    const addInfo = this.transferSyntax;
    const bankAccounts = [
      { bankName: 'HDBANK', acqId: '', accountNo: '073704070005978', accountName: 'LE SINH TUNG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2F7ih5RgwYHWXegsNgxI13?alt=media&token=6f1dd790-076d-4c8c-bf25-007d59969622', note: 'Chi nhánh Hà Nội' },
      { bankName: 'MB', acqId: '', accountNo: '1425162615343', accountName: 'LE SINH TUNG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FdeLHZ6aZDItkimjDS5WZ?alt=media&token=f23a4018-1027-4f4e-a119-025039f41c14', note: 'Chi nhánh Hà Nội' },
      { bankName: 'PVCOMBANK', acqId: '', accountNo: '108002370338', accountName: 'LE SINH TUNG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FUnNTVHPnnNyFDiceMWVL?alt=media&token=72af96f6-0190-4d4c-9505-208e37710602', note: 'Chi nhánh Hà Nội' },
      { bankName: 'SHB', acqId: '970441', accountNo: '0383266631', accountName: 'LE SINH TUNG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FZlspbIaUCBjXYgtFMPbY?alt=media&token=eb7cb1ca-c3a0-46a7-9105-b8cc8b6cd326', note: 'Chi nhánh Hà Nội' },
      { bankName: 'TECHCOMBANK', acqId: '', accountNo: '668888882004', accountName: 'LE SINH TUNG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FzyNFvSBbvPAAjjtnoBIH?alt=media&token=083c9cee-6741-4990-852c-731965325142', note: 'Chi nhánh Hà Nội' },
      { bankName: 'TPBANK', acqId: '', accountNo: '00005500847', accountName: 'LE SINH TUNG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FI98mWPetPvoQtswDn8FM?alt=media&token=f38a83dd-68a1-4f71-ae32-2338015f3a46', note: 'Chi nhánh Hà Nội' },
      { bankName: 'VIB', acqId: '', accountNo: '230904666', accountName: 'LE SINH TUNG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FCFHKNff4oDT9YBbj35wf?alt=media&token=4db26878-0c5e-4d13-9383-7803811d707d', note: 'Chi nhánh Hà Nội' },
      { bankName: 'VIETCOMBANK', acqId: '', accountNo: '1045346406', accountName: 'LE SINH TUNG', addInfo: addInfo, format: 'text', template: 'print', qrURL: 'https://firebasestorage.googleapis.com/v0/b/nhaphangorder-45c36.appspot.com/o/uploads%2Fgallery%2FGAWjQcWKqKJx7Zj0kO2G?alt=media&token=381505c3-5f7c-4d38-85a2-116926c676c3', note: 'Chi nhánh Hà Nội' }
    ];

    /* const httpOptions = {
      headers: new HttpHeaders({
        xClientId: '7f087dee-3f77-4d72-a987-d343c6d6edff',
        xApiKey: 'e9358faf-d8e8-4cfc-86be-49440c4c80b8'
      })
    }; */
    for (const bankAccount of bankAccounts) {
      /* const result: any = await this.apiService.postExternalURL('https://api.vietqr.io/v2/generate', { 
        accountNo: bankAccount.accountNo,
        accountName: bankAccount.accountName,
        acqId: bankAccount.acqId,
        addInfo: bankAccount.addInfo,
        format: bankAccount.format,
        template: bankAccount.template,
      }, httpOptions);

      this.qrs.push({ ...result, ...bankAccount }); */

      this.qrs.push({ ...bankAccount });
    }
  }

}
