import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { FIREBASE_STRUCT, firebaseIdms } from 'src/app/app.constant';
import { ORDER_DEFAULT } from 'src/app/constants/order';
import { Handling } from 'src/app/decorators/handling';
import { Decimal } from 'src/app/models/app.models';
import { AppCurrencyPipe } from 'src/app/pipes/currency/currency.pipe';
import { CalculateFeeService } from 'src/app/services/calculate-fee.service';
import { environment } from 'src/environments/environment';
import { ComponentProps } from "@ionic/core";
import { rechargeGuideParams } from 'src/app/constants/modals.constant';
import { GuideComponent } from '../guide/guide.component';
import { ApiServiceService } from 'src/app/services/api-service.service';

@Component({
  selector: 'app-confirm-deposit',
  templateUrl: './confirm-deposit.component.html',
  styleUrls: ['./confirm-deposit.component.scss'],
})
export class ConfirmDepositComponent implements OnInit, OnDestroy {
  modalTitle: string = "Xác nhận đặt cọc toàn bộ tiền của tất cả đơn hàng được chọn?";
  data: any;
  is_direct_deposit: boolean;
  defaultParam: any;
  serviceFeeParams: any[];
  userUid: any;
  param_percent_deposit: any[];
  unsubscribe$ = new Subject();
  user: any = {};

  availableUsage: number;
  orderTotalPrice: number = 0;
  orderServiceFee: number = 0;
  orderFinalPrice: number = 0;
  orderTotalDeposit: number = 0;
  percent_deposit: any = 0;
  depositAll: boolean = false;
  exchangedRate;
  param_service: any[];

  constructor(
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public fs: AngularFirestore,
    private navParams: NavParams,
    private appCurrency: AppCurrencyPipe,
    public calcFeeService: CalculateFeeService,
    @Inject(firebaseIdms) private firebaseIdms: AngularFireAuth,
    public fireDb: AngularFireDatabase,
    public api: ApiServiceService,
  ) {
    this.data = this.navParams.data.modal.componentProps.data;
    this.is_direct_deposit = this.navParams.data.modal.componentProps.is_direct_deposit;
    this.userUid = this.firebaseIdms.auth.currentUser.uid;

    // Get param percent deposit
    this.getPercentDepositParams().subscribe(p => {
      this.param_percent_deposit = p;
    });

    this.getUser(this.userUid).subscribe(user => {
      this.user = user;
      const { account_balance, credit_limit } = user;
      this.availableUsage = this.formatCurrency(account_balance + credit_limit);
    });

    this.getNewExchangeRate().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(rate => {
      this.exchangedRate = rate;
    });

    this.getDefaultParam().subscribe(params => {
      this.defaultParam = params;
    });

    this._getParamServices().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(p => {
      this.param_service = p;
    });
  }

  ngOnInit() {
    const { orders } = this.data ;

    let orderTotalPrice = 0;
    let orderFinalPrice = 0;
    for (const order of orders) {
      if (order) {
        // Tiền hàng BK
        const orderPrice = new Decimal(order.order_price);
        orderTotalPrice = orderPrice.add(orderTotalPrice).toNumber();
        this.orderTotalPrice = orderTotalPrice;

        // Tổng BK
        const totalPrice = new Decimal(order.order_total_price);
        orderFinalPrice = totalPrice.add(orderFinalPrice).toNumber();
        this.orderFinalPrice = orderFinalPrice;
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @Handling()
  public async onSubmit() {
    const data = { ...this.data };
    const { account_balance = 0, credit_limit = 0 } = this.user;

    const msg1 = `<div>• Số dư tài khoản không đủ để đặt cọc.</div>`;
    const msg2 = `<div>• Số tiền phải đặt cọc là: <strong>${this.formatCurrency(this.orderTotalDeposit)}</strong></div>`;
    const msg3 = `<div>• Hạn mức khả dụng là: <strong>${this.formatCurrency(account_balance + credit_limit)}</strong></div>`;
    const msg4 = `<div>• Số tiền cần nạp thêm là: <strong>${this.formatCurrency(Number(this.orderTotalDeposit) - account_balance - credit_limit)}</strong></div>`;
    const msg5 = `<div>• Vui lòng nạp thêm tiền vào ví điện tử.</div>`;

    try {
      if (data.percent_deposit === 0) {
        throw new Error("<div>• Chưa chọn giá trị đặt cọc.</div>\n<div>• Vui lòng chọn giá trị đặt cọc.</div>");
      }
      if (this.orderTotalDeposit === 0) {
        throw new Error("<div>• Chưa chọn đơn hàng để đặt cọc.</div>\n<div>• Vui lòng chọn đơn hàng.</div>");
      } else {
        const availableLimit = new Decimal(credit_limit).add(account_balance).toNumber();
        if (this.orderTotalDeposit > availableLimit) {
          throw new Error(`${msg1}\n${msg5}\n${msg2}\n${msg3}\n${msg4}`);
        } else {
          // Direct deposit is deposit from cart, otherwise, from order page
          if (this.is_direct_deposit) {
            await this._onDepositDirectlyAll(data, this.userUid, this.depositAll);
          } else {
            await this._onDepositAll(data, this.userUid, this.depositAll);
          }
        }
      }

      this.modalCtrl.dismiss();
      return "<div>• Đặt cọc thành công</div>";
    } catch (error) {
      this.alertCtrl
        .create({
          header: "Thông báo",
          message: error,
          cssClass: "enable-select",
          buttons: [
            {
              text: "Hướng dẫn nạp tiền",
              cssClass: "btn-popup",
              handler: () => {
                const pr: ComponentProps = rechargeGuideParams;
                this.modalCtrl
                  .create({
                    component: GuideComponent,
                    componentProps: pr,
                    cssClass: "sidebar-modal",
                  })
                  .then((modal) => {
                    modal.present();
                  });
              },
            },
            {
              cssClass: "btn-popup",
              role: "cancel",
              text: "OK",
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    }
  }

  public onDismiss() {
    this.modalCtrl.dismiss();
  }

  public async calcOrders() {
    const { orders, percent_deposit } = this.data ;

    let depositAll = false;
    let moneyDepositAll = 0;
    let orderFinalPrice = 0;
    let orderServiceFee = 0;

    for (const order of orders) {
      if (order) {
        this.calcShop(order, Number(percent_deposit));

        const moneyDeposit = new Decimal(order.order_total_price).mul(percent_deposit);
        moneyDepositAll = moneyDeposit.add(moneyDepositAll).toNumber();
        const serviceFee = new Decimal(order.order_service_fee);
        orderServiceFee = serviceFee.add(orderServiceFee).toNumber();

        const totalPrice = new Decimal(order.order_total_price);
        orderFinalPrice = totalPrice.add(orderFinalPrice).toNumber();
      }
    }

    this.orderServiceFee = orderServiceFee;
    this.orderFinalPrice = orderFinalPrice;
    this.orderTotalDeposit = moneyDepositAll;

    if (percent_deposit === 1) {
      depositAll = true;
    }

    this.depositAll = depositAll; // deposit all
  }

  private getPercentDepositParams() {
    return this.fs.collection(`${FIREBASE_STRUCT.PARAMETER_PERCENT_DEPOSIT.NODE}`, query => {
      return query.where('branchUid', '==', environment.branchUid).orderBy('create_date', 'asc');
    }).valueChanges().pipe(takeUntil(this.unsubscribe$));
  }

  private getUser(userUid: string) {
    return this.fs.doc<any>(`${FIREBASE_STRUCT.USERS.NODE}/${this.userUid}`).snapshotChanges().pipe(
      takeUntil(this.unsubscribe$),
      map(doc => ({ uid: userUid, ...doc.payload.data() })),
      switchMap((user) => {
        let ob: Observable<any>;
        const vipType = user.vip.type;
        if (Number(vipType) === 1) {
          ob = this.fs.collection(FIREBASE_STRUCT.PARAMETER_VIPS_NORMAL.NODE).doc(user.vip.uid).valueChanges();
        } else {
          ob = this.fs.collection(FIREBASE_STRUCT.PARAMETER_VIPS_SPECIAL.NODE).doc(user.vip.uid).valueChanges();
        }
        return ob.pipe(
          map((vip) => {
            return {
              ...user,
              uid: userUid,
              vip: { ...vip, type: vipType, uid: user.vip.uid },
            };
          })
        );
      }),
      first()
    );
  }

  private formatCurrency(num) {
    return this.appCurrency.transform(num, "VND", ",", 0);
  }

  private calcShop(item, percent_deposit: number = 0) {
    const products: any[] = item.products;
    const exchangedRate = Number(this.exchangedRate);
    
    for (const product of products) {
      if (product.product_quantity_min) {
        item.require_min = product.product_quantity_min;
      }
    }
    const minFee = Number(this.defaultParam[ORDER_DEFAULT.PHIDICHVUTOITHIEU.KEY].value);
    item = this.calcFeeService.calcServiceFee(products, this.user, this.param_service, percent_deposit, exchangedRate, minFee, item);
  }

  private getNewExchangeRate() {
    return this.fireDb
      .object(
        `BRANCH_CONFIG/${environment.branchUid}/tygiabaokhach`
      )
      .valueChanges();
  }

  private getServiceFeeParams() {
    return this.fs.collection(`${FIREBASE_STRUCT.PARAMETER_SERVICE_FEE.NODE}`, query => {
      return query.where('branchUid', '==', environment.branchUid);
    }).valueChanges().pipe(takeUntil(this.unsubscribe$));
  }

  private getDefaultParam() {
    return this.fs
      .collection(`${FIREBASE_STRUCT.PARAMETER_DEFAULT.NODE}`)
      .snapshotChanges()
      .pipe(
        map((actions) => {
          const r = {};
          actions.forEach((action) => {
            r[action.payload.doc.id] = action.payload.doc.data();
          });
          return r;
        }),
        takeUntil(this.unsubscribe$)
      );
  }

  _onDepositAll(data, userUid, all: boolean = false) {
    return this.api.post("orders/depositAllOrder", {
      ...data,
      user_uid: userUid,
      depositAll: all,
    });
  }

  _onDepositDirectlyAll(data, userUid, all: boolean = false) {
    const { defaultParam, orders, percent_deposit, user } = data;
    const request = {
      user_uid: userUid,
      depositAll: all,
      orders: orders.map(order => ({ is_all: order.is_all, shop: { ...order } })),
      defaultParam,
      percent_deposit,
      user,
    }

    return this.api.post("orders/depositProductsInCart", request);
  }

  private _getParamServices() {
    return this.fs.collection<any>(`${FIREBASE_STRUCT.PARAMETER_SERVICE_FEE.NODE}`, q =>
      q.where('branchUid', '==', environment.branchUid).orderBy('create_date', 'asc')
    ).snapshotChanges().pipe(
      map(snaps => snaps.map(snap => ({ uid: snap.payload.doc.id, ...snap.payload.doc.data() }))),
    );
  }
}
