import { ApiServiceService } from './api-service.service';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    public apiService: ApiServiceService,
    public afFunc: AngularFireFunctions
    ) { }

  public async getServerTime() {
    try {
      const getServerTime = this.afFunc.functions.httpsCallable('getServerTime');
      const result = await getServerTime();
      return result.data;
    } catch (error) {
      return new Date().getTime();
    }
  }

  public waitFor = (timer: number) => new Promise(resolve => setTimeout(resolve, timer));
}
