
export const orderGuideParams = {
    modalTitle: 'Hướng dẫn đặt hàng',
    contentNode: 'home_order_guide_panda_order'
};

export const rechargeGuideParams = {
    modalTitle: 'Hướng dẫn nạp tiền',
    contentNode: 'home_recharge_guide'
};

export const pricingParams = {
    modalTitle: 'Bảng giá',
    contentNode: 'home_price'
};

export const orderDepositParams = {
    modalTitle: 'Quy định tạo đơn hàng Order',
    contentNode: 'home_deposit_order_rule_panda_order'
};
