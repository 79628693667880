import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { firebaseIdms, FIREBASE_STRUCT, ORDER_STATUS, PAYMENT_HISTORY_TYPE, PAYMENT_REQUEST_STATUS } from 'src/app/app.constant';
import { Decimal } from 'src/app/models/app.models';
import { CommonService } from 'src/app/services/common.service';
import * as firebase from 'firebase/app';
import { Handling } from 'src/app/decorators/handling';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-withdraw-money',
  templateUrl: './withdraw-money.component.html',
  styleUrls: ['./withdraw-money.component.scss'],
})
export class WithdrawMoneyComponent implements OnInit {
  uid;
  negative_money;
  user: any;
  recharge_types: any[];
  recharge_type: any;
  recharge_locations: any[];
  recharge_location: any;
  recharge_type_sub = new BehaviorSubject<number>(2);
  recharge_money: number;
  note: string;
  order_debt: number;
  consignment_order_debt: number;
  unsubscribe$ = new Subject();
  recharge_bank_number: number;
  recharge_bank_account: string;

  constructor(
    public modalCtrl: ModalController,
    @Inject(firebaseIdms) public auth: AngularFireAuth,
    public fs: AngularFirestore,
    public navParams: NavParams,
    public commonService: CommonService
  ) {
    this.uid = this.navParams.data.modal.componentProps.uid;
    this.negative_money = Boolean(this.navParams.data.modal.componentProps.negative_money);
    if (this.uid !== '') {
      this.fs.doc(`${FIREBASE_STRUCT.USERS.NODE}/${this.uid}`).valueChanges().pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(async (obj: any) => {
        this.user = obj;
        await this.getDebt(obj.id);
        this.user.usage_balance = new Decimal(obj.account_balance)
          .sub(this.order_debt)
          .sub(this.consignment_order_debt)
          .toNumber();
      });

      // for new site, just recharge by banking type
      this.recharge_types = [
        // { key: 1, value: 'Tiền mặt' },
        { key: 2, value: 'Chuyển khoản' }
      ];

      this.getRechargeLocations().subscribe(locations => {
        this.recharge_location = null;
        this.recharge_locations = locations;
      });
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSelectType() {
    this.recharge_type_sub.next(this.recharge_type);
  }

  getOrderByCustomerId(customer_id) {
    return this.fs.firestore.collection(FIREBASE_STRUCT.ORDERS.NODE)
      .where('order_user_id', '==', customer_id)
      .where('order_status_key', '==', ORDER_STATUS.DAVEKHO.KEY)
      .get().then(snapshot => snapshot.docs.map(doc => ({ ...doc.data(), uid: doc.id })));
  }

  async getDebt(customer_id) {
    const orders = await this.getOrderByCustomerId(customer_id);
    let order_debt = 0;
    let consignment_order_debt = 0;

    orders.forEach((order: any) => {
      order_debt = new Decimal(order_debt).add(order.order_lack_of_paid).toNumber();
    });

    this.order_debt = order_debt;
    this.consignment_order_debt = consignment_order_debt;
  }

  getRechargeLocations() {
    return this.recharge_type_sub.pipe(
      debounceTime(2000),
      switchMap(type => {
        if (Number(type) === 1) {
          return this.fs.collection<any>(`${FIREBASE_STRUCT.LOCATION_PAYMENT_CASH.NODE}`, query => {
            return query.orderBy('create_date', 'asc');
          }).snapshotChanges().pipe(
            map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() })))
          );
        } else if (Number(type) === 2) {
          return this.fs.collection<any>(`${FIREBASE_STRUCT.LOCATION_PAYMENT_TRANFER.NODE}`, query => {
            return query.orderBy('create_date', 'asc');
          }).snapshotChanges().pipe(
            map(sns => sns.map(sn => ({ uid: sn.payload.doc.id, ...sn.payload.doc.data() })))
          );
        } else { return of([]); }
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  onDismiss() {
    this.modalCtrl.dismiss();
  }

  @Handling({
    header: "Xác nhận yêu cầu rút tiền?",
    message: "Vui lòng click [Đồng ý] để yêu cầu rút tiền.",
    buttons: [
      {
        text: "Hủy",
        role: "cancel",
        cssClass: "cancel-btn",
      },
      {
        text: "Đồng ý",
        role: "ok",
        cssClass: "ok-btn",
      },
    ],
  })
  async onSubmit() {
    const fs = firebase.firestore();
    const p = [];
    try {
      const c = await this.commonService.getServerTime();
      const user = { ...this.user };
      const recharge_money = Number(this.recharge_money || 0);
      if (this.uid !== '') {
        if (!this.negative_money && recharge_money > Number(user.usage_balance)) {
          throw new Error('Số tiền rút vượt quá giá trị rút tiền tối đa. Vui lòng kiểm tra lại.');
        }

        // Commit into PAYMENT_REQUESTS
        fs.collection(FIREBASE_STRUCT.PAYMENT_REQUESTS.NODE).add({
          payment_date_created: c,
          payment_type_key: PAYMENT_HISTORY_TYPE.RUTTIEN.KEY,
          payment_type: PAYMENT_HISTORY_TYPE.RUTTIEN.VALUE,
          payment_content: `${this.note ? 'Rút tiền - ' + this.note : 'Rút tiền'}`,
          payment_amount: recharge_money,
          payment_create_user: this.auth.auth.currentUser.displayName,
          payment_name: this.recharge_types[0].value, // for new site, just recharge by banking type
          customer_id: user.id,
          customer_code: user.user_code,
          customer_full_name: user.full_name,
          customer_phone_number: user.phone,
          customer_email: user.email,
          customer_user_uid: this.uid,
          location: this.recharge_location.name,
          note: this.note || '',
          status_key: PAYMENT_REQUEST_STATUS.GUIYEUCAU.KEY, // waiting for the approvement from admin
          status_text: PAYMENT_REQUEST_STATUS.GUIYEUCAU.VALUE,
          recharge_bank_number: this.recharge_bank_number,
          recharge_bank_account: this.recharge_bank_account,
          branch_uid: environment.branchUid
        });

        this.modalCtrl.dismiss();
        return 'Yêu cầu rút tiền thành công';
      } else {
        throw new Error('Có lỗi xảy ra khi rút tiền. Vui lòng kiểm tra lại.');
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
