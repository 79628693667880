export const firebaseBo = 'firebaseBo';
export const firebaseIdms = 'firebaseIdms';
export const PAGE_SIZE = 30;
export const FIREBASE_STRUCT = {
    ADMIN_ORDERS: {
        NODE: 'ADMIN_ORDERS'
    },
    ADMIN_CONSIGNMENT: {
        NODE: 'ADMIN_CONSIGNMENT'
    },
    USERS: {
        NODE: 'USERS',
        UKM: 'ukm'
    },
    CONVERSATIONS: {
        NODE: 'CONVERSATIONS'
    },
    USERS_ACCOUNT: {
        NODE: 'USERS_ACCOUNT'
    },
    USERS_ACTION_ROLE: {
        NODE: 'USERS_ACTION_ROLE',
        KEY: 'IsH3Gk3Rk9LuC7rukXwY'
    },
    USERS_ADMIN: {
        NODE: 'USERS_ADMIN'
    },
    USER_INFORS: {
        NODE: 'USER_INFOS',
        ACCOUNT_BALANCE: 'account_balance',
        CREDIT_LIMIT: 'credit_limit',
        AVAILABLE_LIMIT: 'available_limit',
        LACK_OF_PAID: 'lack_of_paid'
    },
    USER_LUCKY_WHEEL_PROMOTION: {
        NODE: 'USER_LUCKY_WHEEL_PROMOTION'
    },
    USER_PROMOTIONS: {
        NODE: 'USER_PROMOTIONS'
    },
    ORDERS: {
        NODE: 'ORDERS'
    },
    ORDERS_ALARM_TRANSPORT_CODE: {
        NODE: 'ORDERS_ALARM_TRANSPORT_CODE'
    },
    ORDERS_ALARM_WEIGHT: {
        NODE: 'ORDERS_ALARM_WEIGHT'
    },
    ORDER_REQUEST_EXPORT: {
        NODE: 'ORDER_REQUEST_EXPORT'
    },
    ORDERS_AUTO_FINISH: {
        NODE: 'ORDERS_AUTO_FINISH'
    },
    BRANCH_CONFIG: {
        NODE: 'BRANCH_CONFIG'
    },
    CARTS: {
        NODE: 'CARTS',
        UID: 'uid',
        PRODUCTS: 'products'
    },
    CART_SHOPS: {
        NODE: 'CART_SHOPS',
        SHOP_NAME: 'shop_name',
        PRODUCTS_DEAL: {
            NODE: 'PRODUCTS_DEAL',
            UID: 'uid'
        }
    },
    CART_BADGES: {
        NODE: 'CART_BADGES',
    },
    DELIVERY_BILL: {
        NODE: 'DELIVERY_BILL'
    },
    CONSIGNMENT_TRANSPORT: {
        NODE: 'CONSIGNMENT_TRANSPORT'
    },
    ORDER_TRANSPORT: {
        NODE: 'ORDER_TRANSPORT'
    },
    PRODUCTS_DEAL: {
        NODE: 'PRODUCTS_DEAL',
        ORDER_CODE: 'ladding_code',
        ORDER_STATUS: 'status',
        JOURNEY: 'journey',
        SHOP_NAME: 'shop_name',
        PRODUCT_IMAGE: 'product_image',
        PRODUCT_NAME: 'product_name',
        PRODUCT_QUANTITY: 'product_quantity',
        PRODUCT_WEIGHT: 'product_weight',
        PRODUCT_PRICE: 'product_price',
        PRODUCT_DISCOUNT: 'product_discount',
        PRODUCT_PRICE_USD: 'product_usd',
        PRODUCT_LACK_OF_PAID: 'product_lack_of_paid',
        DATE_ADD_TO_CART: 'date_add_to_cart',

    },
    PROMOTION: {
        NODE: 'PARAMETER_PROMOTION'
    },
    PARAMETER_PROMOTION_CONFIGS: {
        NODE: 'PARAMETER_PROMOTION_CONFIGS'
    },
    PARAMETER_DEFAULT: {
        NODE: 'PARAMETER_ORDER_DEFAULT'
    },
    PARAMETER_TRANSPORT_FEE_FAST: {
        NODE: 'PARAMETER_ORDER_TRANSPORT_FEE_FAST'
    },
    PARAMETER_ORDER_TRANSPORT_FEE: {
        NODE: 'PARAMETER_ORDER_TRANSPORT_FEE'
    },
    PARAMETER_TRANSPORT_FEE_SLOW: {
        NODE: 'PARAMETER_ORDER_TRANSPORT_FEE_SLOW'
    },
    PARAMETER_SERVICE_FEE: {
        NODE: 'PARAMETER_ORDER_SERVICE_FEE'
    },
    PARAMETER_PERCENT_DEPOSIT: {
        NODE: 'PARAMETER_ORDER_PERCENT_DEPOSIT'
    },
    PARAMETER_GOODS_CHECK_FEE: {
        NODE: 'PARAMETER_ORDER_GOODS_CHECK_FEE'
    },
    CONSIGNMENT_PARAMETER_DEFAULT: {
        NODE: 'PARAMETER_CONSIGNMENT_ORDER_DEFAULT'
    },
    CONSIGNMENT_PARAMETER_TRANSPORT_FEE: {
        NODE: 'PARAMETER_CONSIGNMENT_ORDER_TRANSPORT_FEE'
    },
    PARAMETER_CONSIGNMENT_ORDER_INSURRANCE_FEE: {
        NODE: 'PARAMETER_CONSIGNMENT_ORDER_INSURRANCE_FEE'
    },
    PARAMETER_VIPS_NORMAL: {
        NODE: 'PARAMETER_VIPS_NORMAL'
    },
    PARAMETER_VIPS_SPECIAL: {
        NODE: 'PARAMETER_VIPS_SPECIAL'
    },
    PARAMETER_LUCKY_WHEEL_PROMOTION: {
        NODE: 'PARAMETER_LUCKY_WHEEL_PROMOTION'
    },
    PARAMETER_CONTENT: {
        NODE: 'PARAMETER_CONTENT',
        HOME_ABOUT: 'home_about',
        HOME_ALARM: 'home_alarm',
        HOME_CNE: 'home_cne',
        HOME_COMMITMENT: 'home_commitment',
        HOME_CONTACT: 'home_contact',
        HOME_NEWS: 'home_news',
        HOME_PAYMENT: 'home_payment',
        HOME_PRICE: 'home_price',
        HOME_RECRUIMENT: 'home_recruiment',
        HOME_RULE: 'home_rule',
        ORDER_RULE: 'home_create_order_rule',
        HOME_SERVICE: 'home_service',
        HOME_TRANSPORT: 'home_transport',
        HOME_INFORM: 'home_inform',
        HOME_INFORM_NEW_BRANCHES: 'home_inform_new_branches',
        CUSTOMER_GUIDE: {
            NODE: 'customer_guide',
            ORDER_DETAIL: 'chi_tiet_don_hang',
            CONSIGNMENT_DETAIL: 'chi_tiet_don_ky_gui'
        }
    },
    COMPLAINS: {
        NODE: 'COMPLAINS',
        ORDER_UID: 'order_uid',
        USER_UID: 'user_uid',
        DATE_CREATED: 'date_create',
        STATUS_KEY: 'status_key',
        STATUS_TXT: 'status_txt',
        CONTENT: 'content'
    },
    UID_INFO: {
        NODE: 'UID_INFO',
        BAG_UID: 'bag_uid',
        ORDER_UID: 'order_uid',
        APP_UID: 'app_uid',
        USER_CUSTOMER_UID: 'user_customer_uid',
        PAYMENT_UID: 'payment_uid',
        DELIVERY_BILL_UID: 'delivery_bill_uid'
    }
    ,
    FAVORITES: {
        NODE: 'FAVORITES'
    },
    APPLICATIONS: {
        NODE: 'APPLICATIONS',
    },
    APPLICATION_DETAIL: {
        NODE: 'APPLICATION_DETAIL',
        APP_CODE: 'APP_CODE',
        CONTENT: 'CONTENT'
    },
    PAYMENT_HISTORY: {
        NODE: 'PAYMENT_HISTORY'
    },
    CONSIGNMENTS: {
        NODE: 'CONSIGNMENTS'
    },
    CONSIGNMENT_EXTRA_FEES: {
        NODE: 'extra_fees'
    },
    TRANSPORTS: {
        NODE: 'TRANSPORTS'
    },
    ORDER_DETAIL_PAYMENT_HISTORY: {
        NODE: 'payment_histories'
    },
    CONSIGNMENT_ORDER_DETAIL_PAYMENT_HISTORY: {
        NODE: 'payment_histories'
    },
    ORDER_DETAIL_ACTION_HISTORY: {
        NODE: 'action_histories'
    },
    CONSIGNMENT_ORDER_DETAIL_ACTION_HISTORY: {
        NODE: 'action_histories'
    },
    MESSAGE_ROOMS: {
        NODE: 'MESSAGE_ROOMS'
    },
    STATISTIC_GET_LINK: {
        NODE: 'STATISTIC_GET_LINK'
    },
    NOTIFICATIONS: {
        NODE: 'NOTIFICATIONS'
    },
    LOCATION_PAYMENT_CASH: {
        NODE: 'PARAMETER_LOCATION_PAYMENT_CASH'
    },
    LOCATION_PAYMENT_TRANFER: {
        NODE: 'PARAMETER_LOCATION_PAYMENT_TRANFER'
    },
    PAYMENT_HISTOTY: {
        NODE: 'PAYMENT_HISTORY'
    },
    STATISTIC: {
        NODE: 'STATISTIC'
    },
    PAYMENT_REQUESTS: {
        NODE: 'PAYMENT_REQUESTS'
    },
};

export const ADMIN_USERS = {
    NHANVIENDATHANG: {
        KEY: 'um7XDDHI1bVBZCBUFh1n',
    },
    NHANVIENCHAMSOCKHACHHANG: {
        KEY: 'EOygYEWktZqF4sXGgukr'
    },
    NHANVIENKINHDOANH: {
        KEY: '8Im9Ilu1znCisOAj4fkX'
    },
    TRUONGPHONGCHAMSOCKHACHHANG: {
        KEY: 'owkdqtsRL0TIkaKntYDX'
    },
    TRUONGPHONGKINHDOANH: {
        KEY: 'Iph8KNXQnTXHXWudwtk7'
    }
};


export const ORDER_STATUS = {
    TATCA: {
        KEY: 99,
        // VALUE: 'Chờ báo giá',
        VALUE: 'TẤT CẢ',
        SLUG: 'tat-ca'
    },
    CHOBAOGIA: {
        KEY: 0,
        // VALUE: 'Chờ báo giá',
        VALUE: 'CHỜ BÁO GIÁ',
        SLUG: 'cho-bao-gia'
    },
    DABAOGIA: {
        KEY: 1,
        // VALUE: 'Đã báo giá'
        VALUE: 'ĐÃ BÁO GIÁ',
        SLUG: 'da-bao-gia'
    },
    DATCOC: {
        KEY: 2,
        // VALUE: 'Đặt cọc'
        VALUE: 'ĐẶT CỌC',
        SLUG: 'dat-coc'
    },
    CHOXACNHAN: {
        KEY: 2.1,
        VALUE: 'CHỜ XÁC NHẬN',
        SLUG: 'cho-xac-nhan'
    },
    DAXACNHAN: {
        KEY: 2.2,
        VALUE: 'ĐÃ XÁC NHẬN',
        SLUG: 'da-xac-nhan'
    },
    DATHANG: {
        KEY: 3,
        // VALUE: 'Đặt hàng'
        VALUE: 'ĐẶT HÀNG',
        SLUG: 'dat-hang'
    },
    THANHTOANCHOSHOP: {
        KEY: 4,
        // VALUE: 'Thanh toán cho shop'
        VALUE: 'THANH TOÁN CHO SHOP',
        SLUG: 'thanh-toan-cho-shop'
    },
    DAVEKHOTQ: {
        KEY: 5,
        // VALUE: 'Đã về kho TQ'
        VALUE: 'ĐÃ VỀ KHO TQ',
        SLUG: 'da-ve-kho-tq'
    },
    VANCHUYENTQVN: {
        KEY: 6,
        // VALUE: 'Vận chuyển TQ-VN'
        VALUE: 'VẬN CHUYỂN TQ-VN',
        SLUG: 'van-chuyen-tq-vn'
    },
    VANCHUYENHNSG: {
        KEY: 7.1,
        VALUE: 'VẬN CHUYỂN HN-SG',
        SLUG: 'van-chuyen-hn-sg'
    },
    DAVEKHO: {
        KEY: 7.2,
        VALUE: 'ĐÃ VỀ KHO',
        SLUG: 'da-ve-kho'
    },
    /* DAVEVN: {
        KEY: 7,
        // VALUE: 'Đã về VN'
        VALUE: 'ĐÃ VỀ VN',
        SLUG: 'da-ve-vn'
    }, */
    DATHANHTOAN: {
        KEY: 8,
        // VALUE: 'Đã thanh toán'
        VALUE: 'ĐÃ THANH TOÁN',
        SLUG: 'da-thanh-toan'
    },
    GIAOHANG: {
        KEY: 9,
        VALUE: 'GIAO HÀNG',
        SLUG: 'dang-giao-hang'
    },
    DAGIAO: {
        KEY: 10,
        // VALUE: 'Đã giao'
        VALUE: 'ĐÃ GIAO',
        SLUG: 'da-giao-hang'
    },
    HOANTHANH: {
        KEY: 11,
        // VALUE: 'Hoàn thành'
        VALUE: 'HOÀN THÀNH',
        SLUG: 'hoan-thanh'
    },
    HUYDON: {
        KEY: 12,
        // VALUE: 'Hủy đơn'
        VALUE: 'HỦY ĐƠN',
        SLUG: 'huy-don'
    }
};

export const DELIVERY_BILL_STATUS = {
    CHUAXULY: {
        KEY: 0,
        VALUE: 'Chưa xử lý'
    },
    DAXULY: {
        KEY: 1,
        VALUE: 'Đã xử lý'
    }
};

export const COMPLAIN_STATUS = {
    CHUAXULY: {
        KEY: 0,
        VALUE: 'Chưa xử lý'
    },
    DANGXULY: {
        KEY: 1,
        VALUE: 'Đang xử lý'
    },
    HOANTHANH: {
        KEY: 2,
        VALUE: 'Hoàn thành'
    }
};

export const PAYMENT_HISTORY_TYPE = {
    NAPTIEN: {
        KEY: 0,
        VALUE: 'Nạp tiền'
    },
    DATCOC: {
        KEY: 1,
        VALUE: 'Đặt cọc'
    },
    THANHTOAN: {
        KEY: 2,
        VALUE: 'Thanh toán'
    },
    RUTTIEN: {
        KEY: 3,
        VALUE: 'Rút tiền'
    },
    HOANTIEN: {
        KEY: 4,
        VALUE: 'Hoàn tiền'
    },
    KHUYENMAI: {
        KEY: 5,
        VALUE: 'Khuyến mãi'
    }
};

export const PAYMENT_REQUEST_STATUS = {
    GUIYEUCAU: {
        KEY: 0,
        VALUE: 'Gửi yêu cầu'
    },
    DANGXULY: {
        KEY: 1,
        VALUE: 'Đang xử lý'
    },
    HOANTHANH: {
        KEY: 2,
        VALUE: 'Hoàn thành'
    },
    HUY: {
        KEY: 99,
        VALUE: 'Huỷ'
    }
}

export const CONSIGNMENT_STATUS = {
    TATCA: {
        KEY: 99,
        VALUE: 'TẤT CẢ',
        SLUG: 'tat-ca'
    },
    CHUAVE: {
        KEY: 0,
        // VALUE: 'Chưa về'
        VALUE: 'CHƯA VỀ',
        SLUG: 'chua-ve'
    },
    NHAPKHOTQ: {
        KEY: 1,
        // VALUE: 'Nhập Kho TQ'
        VALUE: 'NHẬP KHO TQ',
        SLUG: 'nhap-kho-tq'
    },
    VANCHUYENTQVN: {
        KEY: 2,
        // VALUE: 'Vận chuyển TQ-VN'
        VALUE: 'VẬN CHUYỂN TQ-VN',
        SLUG: 'van-chuyen-tqvn'
    },
    VANCHUYENHNSG: {
        KEY: 3.1,
        VALUE: 'VẬN CHUYỂN HN-SG',
        SLUG: 'van-chuyen-hn-sg'
    },
    DAVEKHO: {
        KEY: 3.2,
        VALUE: 'ĐÃ VỀ KHO',
        SLUG: 'da-ve-kho'
    },
    /* NHAPKHOVN: {
        KEY: 3,
        // VALUE: 'Nhập kho VN'
        VALUE: 'NHẬP KHO VN',
        SLUG: 'nhap-kho-vn'
    }, */
    DATHANHTOAN: {
        KEY: 4,
        // VALUE: 'Đã thanh toán'
        VALUE: 'ĐÃ THANH TOÁN',
        SLUG: 'da-thanh-toan'
    },
    GIAOHANG: {
        KEY: 5,
        // VALUE: 'Giao hàng'
        VALUE: 'GIAO HÀNG',
        SLUG: 'giao-hang'
    },
    DAGIAO: {
        KEY: 6,
        VALUE: 'ĐÃ GIAO',
        SLUG: 'da-giao'
    },
    HOANTHANH: {
        KEY: 7,
        // VALUE: 'Hoàn thành'
        VALUE: 'HOÀN THÀNH',
        SLUG: 'hoan-thanh'
    }
};

export const ORDER_REQUEST_EXPORT_STATUS = {
    CHUAXULY: {
        KEY: 0,
        VALUE: 'Chưa xử lý'
    },
    DAXULY: {
        KEY: 1,
        VALUE: 'Đã xử lý'
    },
};