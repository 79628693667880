import { Component, Inject } from '@angular/core';

import { I18nService } from './cores/i18n.service';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { switchMap, map } from 'rxjs/operators';
import { User } from 'firebase/app';
import { FIREBASE_STRUCT, firebaseIdms } from './app.constant';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private i18nService: I18nService,
    @Inject(firebaseIdms) private firebaseIdms: AngularFireAuth,
    private firestore: AngularFirestore,
    private appService: AppService
  ) {
    this.initLang();
    this.initializeApp();

    this.getUser().subscribe(async (user: any) => {
      if (user) {
        const customEvent: CustomEvent = new CustomEvent('authentication', {
          bubbles: true,
          detail: {
            user: {
              user_name: user.full_name,
              user_uid: user.uid
            }
          }
        });
        document.dispatchEvent(customEvent);
      } else {
        const customEvent: CustomEvent = new CustomEvent('authentication', {
          bubbles: true,
          detail: {
            user: null
          }
        });
        document.dispatchEvent(customEvent);
      }
    });
  }

  private initLang() {
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
    this.i18nService.language = 'vi-VN';
  }

  private initializeApp() {

  }

  getUser() {
    return this.firebaseIdms.authState.pipe(
      switchMap((user: User) => {
        if (user) {
          return this.firestore.collection(FIREBASE_STRUCT.USERS.NODE).doc(user.uid).snapshotChanges().pipe(
            map(doc => ({ uid: user.uid, ...doc.payload.data() as any }))
          );
        } else {
          return of(null);
        }
      })
    );
  }

}
