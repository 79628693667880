import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './save-html/safe-html.pipe';
import { RemoveUnicodePipe } from './remove-unicode/remove-unicode.pipe';
import { AppCurrencyPipe } from './currency/currency.pipe';

@NgModule({
    declarations: [
        AppCurrencyPipe,
        SafeHtmlPipe,
        RemoveUnicodePipe
    ],
    exports: [
        AppCurrencyPipe,
        SafeHtmlPipe,
        RemoveUnicodePipe
    ],
    providers: [
        AppCurrencyPipe,
        SafeHtmlPipe,
        RemoveUnicodePipe
    ]
})
export class PipesModule { }
