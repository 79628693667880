import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  private url: string = environment.serverUrl;

  constructor(public http: HttpClient) { }

  get(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: {}
      };
    }
    if (params) {
      reqOpts.params = params;
    }

    return this.http.get(this.url + '/' + endpoint, reqOpts).pipe(
      map(res => JSON.stringify(res))
    ).toPromise();
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.url + '/' + endpoint, { ...body, branchUid: environment.branchUid }, reqOpts).pipe(
      map(res => res)
    ).toPromise();
  }

  postExternalURL(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(endpoint, { ...body, branchUid: environment.branchUid }, reqOpts).pipe(
      map(res => res)
    ).toPromise();
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.url + '/' + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }
}
